<template>
  <div class="vps-detail-backup">
    <page-block :title="$t('available.title')" class="vps-detail-backup__block">
      <main-card>
        <div class="standart-text">
          {{ $t('available.text') }}
        </div>
        <base-button nowrap>
          {{ $t('available.btn') }}
        </base-button>
      </main-card>
    </page-block>
    <page-block :title="$t('created.title')" class="vps-detail-backup__block">
      <plain-button color="primary" nowrap>
        {{ $t('created.btn') }}
      </plain-button>
      <backup-banner :list="list" />
    </page-block>
    <page-block :title="$t('schedule.title')" class="vps-detail-backup__block">
      <plain-button color="primary" nowrap>
        {{ $t('schedule.btn') }}
      </plain-button>
      <backup-table />
    </page-block>
  </div>
</template>

<script>
import PageBlock from '../components/PageBlock.vue';
import MainCard from '@/components/MainCard/MainCard';
import BackupTable from '../components/BackupTable';
import BackupBanner from '@/components/BackupBanner/BackupBanner.vue';
import { SoftTariff } from '@/models/BillMgr/SoftTariff';
export default {
  name: 'VpsBackups',
  components: {
    BackupBanner,
    PageBlock,
    MainCard,
    BackupTable,
  },
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof SoftTariff,
    },
  },
  data() {
    return {
      list: [
        { title: 'Vinilink Production_backup_20200628153751_centos_msk' },
        { title: 'Vinilink Production_backup_20200628153751_centos_msk' },
      ],
    };
  },
};
</script>

<i18n>
{
  "ru": {
    "available": {
      "title": "Доступно бэкапов",
      "text": "Вам доступно создание 5 резервных копий. Свободно слотов – 1.Бэкапы можно создавать вручную или настроить расписание",
      "btn": "Увеличить количество слотов"
    },
    "created": {
      "title": "Созданные бэкапы",
      "btn": "Новый бэкап +"
    },
    "schedule": {
      "title": "Расписание",
      "btn": "Новое расписание +"
    }
  }
}
</i18n>
